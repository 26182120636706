import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { CompanyRegistrationSchema } from '../../services/validationSchema';
import { Form, Formik, Field, FieldProps } from 'formik';
import TextInput from '../molecules/textInput';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import { ColorType } from '../atoms/interfaces';
import styled from 'styled-components';
import { submitNetlifyForm } from '../../api';
import { Forms, mandatoryFields, nonMandatory } from '../../services/formData';
import useSnackbar from '../../hooks/useSnackbar';
import HoneyPotHidden from '../HoneyPotHidden';

interface IFormWrapper {
  backgroundColor: ColorType;
}
interface ICompanyApplicationForm {
  title: string;
  description: string;
  backgroundColor: string;
  ctaTitle: string;
}
interface ICompanyRegistrationForm {
  companyName: string;
  firstName: string;
  lastName: string;
  orgNo: string;
  email: string;
  phone: string;
  streetAddress: string;
  postalCode: string;
  postalZone: string;
  // not mandatory
  numberOfEmployees: string;
  industry: string;
  revenue: string;
  message: string;
  tos: boolean;
}

const FormWrapper = styled.div<IFormWrapper>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.textPrimary};
`;

const initialFormValues: ICompanyRegistrationForm = {
  companyName: '',
  firstName: '',
  lastName: '',
  orgNo: '',
  email: '',
  phone: '',
  streetAddress: '',
  postalCode: '',
  postalZone: '',
  numberOfEmployees: '',
  industry: '',
  revenue: '',
  message: '',
  tos: false
};

const CompanyApplicationForm: React.FC<ICompanyApplicationForm> = (props) => {
  const [loading, setIsLoading] = useState(false);
  const { handleMessage } = useSnackbar();

  const handleSubmit = async (
    values: ICompanyRegistrationForm,
    formik: any
  ) => {
    try {
      setIsLoading(true);
      const success = await submitNetlifyForm(
        Forms.COMPANY_APPLICATION,
        values
      );

      if (success) {
        handleMessage('Tack för ditt meddelande vi återkommer så fort vi kan.');
        formik.resetForm();
      } else {
        handleMessage(
          'Formuläret kunde inte skickas. Vänligen försök igen eller kontakta support'
        );
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={CompanyRegistrationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form
          data-netlify="true"
          name={Forms.COMPANY_APPLICATION}
          action="/"
          netlify-honeypot="bot-field"
        >
          <input
            type="hidden"
            name="form-name"
            value={Forms.COMPANY_APPLICATION}
          />
          <HoneyPotHidden />
          <FormWrapper backgroundColor={props.backgroundColor}>
            <Spacer spacing={4} />
            <Typography color="primary" variant="header">
              {props.title}
            </Typography>
            <Typography>{props.description}</Typography>
            <Spacer spacing={2} />
            <Row>
              {mandatoryFields.map((item) => (
                <Col key={item.name} md={4}>
                  <Field name={item.name}>
                    {({ field, meta }: FieldProps) => (
                      <TextInput
                        {...field}
                        {...item}
                        errorMsg={
                          meta.touched && meta.error ? meta.error : undefined
                        }
                      />
                    )}
                  </Field>
                  <Spacer />
                </Col>
              ))}
            </Row>
            <Spacer />
            <Typography color="textPrimary" variant="h3">
              Valfria uppgifter
            </Typography>
            <Spacer />
            <Row>
              {nonMandatory.map((item) => (
                <Col key={item.name} md={item.md}>
                  <Field name={item.name}>
                    {({ field, meta }: FieldProps) => (
                      <TextInput
                        {...field}
                        {...item}
                        errorMsg={
                          meta.touched && meta.error ? meta.error : undefined
                        }
                      />
                    )}
                  </Field>
                  <Spacer />
                </Col>
              ))}
            </Row>

            <Row>
              <Col offset={{ md: 9 }} md={3}>
                <Spacer />
                <Button
                  isLoading={loading}
                  type="submit"
                  disabled={!formik.isValid}
                >
                  {props.ctaTitle}
                </Button>
              </Col>
            </Row>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyApplicationForm;
