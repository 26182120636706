import React from 'react';
import HeroBanner from '../components/organisms/heroBanner';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import CompanyApplicationForm from '../components/organisms/companyApplicationForm';

import theme from '../styles/theme';
import Container from '../components/atoms/container';
import SEO from '../components/seo';

const CompanyApplication = () => (
  <>
    <SEO title="Företagsansökan" />
    <div style={{ backgroundColor: theme.colors.formBackground }}>
      <HeroBanner
        leftPane={
          <>
            <Spacer spacing={6} />
            <Typography color="textSecondary" variant="header">
              panBox för företag
            </Typography>
            <Spacer spacing={1} />
          </>
        }
      />
      <Container>
        <CompanyApplicationForm
          title="Företagsansökan"
          description="Fyll i dina uppgifter, så kontaktar vi dig."
          backgroundColor="formBackground"
          ctaTitle="Skicka"
        />
        <Spacer spacing={2} />
      </Container>
    </div>
  </>
);

export default CompanyApplication;
